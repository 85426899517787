<template>
  <Navbar />

  <div class="container jumbotron mt-3">
    <div class="row">
      <div class="col-md-8 order-md-1">
        <h4 class="mb-3 font-weight-bold">
          {{ $t("PaymentPage.PAYMENT_METHOD") }}
        </h4>

        <div class="d-block my-3">
          <div class="custom-control custom-radio">
            <input
              id="credit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              checked=""
              required=""
              @change="onPaymentMethodChange"
            />
            <label class="custom-control-label" for="credit"
              >Mobile Money</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              id="debit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              required=""
              @change="onPaymentMethodChange"
            />
            <label class="custom-control-label" for="debit"
              >Visa/Mastercard</label
            >
          </div>
        </div>

        <div v-show="isCreditCardPayment">
          <form class="needs-validation" novalidate="">
            <hr class="mb-4" />
            <h4 class="mb-3 font-weight-bold">
              {{ $t("PaymentPage.BILLING_INFORMATION") }}
            </h4>
            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="adresse"
                  >Adress<span style="color: red">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="adresse"
                  placeholder=""
                  required=""
                  v-model="customer_address"
                  :class="getAdressClass"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label for="telephone"
                  >Phone number<span style="color: red">*</span></label
                >
                <input
                  type="tel"
                  class="form-control"
                  id="telephone"
                  placeholder=""
                  required=""
                  :class="getPhoneClass"
                  v-model="customer_phone_number"
                />
              </div>
              <div class="col-md-3 mb-3">
                <label for="ville">City<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="ville"
                  placeholder=""
                  required=""
                  v-model="customer_city"
                  :class="getCityClass"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="country"
                  >Country<span style="color: red">*</span></label
                >
                <select
                  class="custom-select d-block w-100"
                  id="country"
                  required=""
                  v-model="customer_country"
                >
                  <option v-for="c in countries" :key="c.name" :value="c.code">
                    {{ c.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 mb-3">
                <label for="zip">State<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  placeholder=""
                  required=""
                  v-model="customer_state"
                  :class="getStateClass"
                />
              </div>
              <div class="col-md-3 mb-3">
                <label for="zip">Zip<span style="color: red">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  placeholder=""
                  required=""
                  v-model="customer_zip_code"
                  :class="getZipClass"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4 order-md-2 mb-4">
        <h4 class="d-flex justify-content-between align-items-center mb-3 ml-4">
          <strong class="text-center font-weight-bold">{{
            $t("PaymentPage.PAYMENT_SUMMARY")
          }}</strong>
        </h4>
        <ul class="list-group mb-3">
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">{{ $t("Navbar.COURSES") }}</h6>
              <strong class="text-muted">{{ course.title }}</strong>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">{{ $t("PaymentPage.TEACHER") }}</h6>
              <strong class="text-muted">
                {{ course.teacher.firstName }}
                {{ course.teacher.lastName }}</strong
              >
            </div>
          </li>

          <li
            class="list-group-item d-flex justify-content-between bg-light"
            v-if="isCouponValide"
          >
            <div class="text-success">
              <h6 class="my-0">Promo code</h6>
              <small>{{ code }}</small>
            </div>
            <div>
              <span class="text-success"
                >- {{ numberFormat(course.price - newPrice) }}
                {{ course.currency }}</span
              ><br />
              <small
                ><button
                  type="reset"
                  class="btn btn-info btn-sm ml-1"
                  @click="
                    isCouponValide = false;
                    code = '';
                    newPrice = null;
                  "
                >
                  {{ $t("PaymentPage.CANCEL") }}
                </button></small
              >
            </div>
          </li>

          <li class="list-group-item d-flex justify-content-between">
            <span>Total </span>
            <strong class="font-weight-bold">
              <span :class="newPrice !== null ? 'text-through' : ''"
                >{{ numberFormat(course.price) }} {{ course.currency }}</span
              >&nbsp;&nbsp;&nbsp;
              <span v-if="newPrice !== null"
                >{{ numberFormat(newPrice) }} {{ course.currency }}</span
              ></strong
            >
          </li>
        </ul>

        <form class="card p-2" @submit.prevent="checkCode">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Promo code"
              v-model="code"
            />
            <div class="input-group-append">
              <button type="submit" class="btn btn-secondary">
                {{ $t("PaymentPage.APPLY") }}
                <span
                  class="spinner-grow spinner-grow-sm"
                  style="width: 1.5rem; height: 1.5rem"
                  role="status"
                  aria-hidden="true"
                  v-show="isPromoCodeChecking"
                ></span>
              </button>
            </div>
          </div>
        </form>
        <div class="form-group form-select">
          <label for="deivise">Devise:</label>
          <select id="devise" class="form-control" v-model="selectedCurrency">
            <option value="XAF">FCFA CEMAC</option>
            <option value="XOF">FCFA CEDEAO</option>
          </select>
        </div>

        <hr class="mb-4" />
        <button
          class="btn btn-primary btn-lg btn-block"
          @click="getPaymentLinkRequest"
          :disabled="isSendable"
        >
          {{ $t("PaymentPage.PAY_NOW") }}

          <span
            class="spinner-grow spinner-grow-sm"
            style="width: 1.5rem; height: 1.5rem"
            role="status"
            aria-hidden="true"
            v-show="loading"
          ></span>
        </button>
      </div>
    </div>
  </div>

  <DashboardFooter />
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";
import numeral from "numeral";
import DashboardFooter from "@/components/layouts/DashboardFooter";
import countries from "@/store/countries.json";
export default {
  components: {
    Navbar,
    DashboardFooter,
  },
  data() {
    return {
      loading: false,
      isPromoCodeChecking: false,
      isCouponValide: false,
      paymentLink: "",
      selectedCurrency: this.$store.state.defaultCurrency,
      course: {
        id: 0,
        slug: "",
        title: "",
        description: "",
        price: 0,
        currency: "",
        coursePicture: {
          fullSize: "",
          thumbnail: "",
          mediumSquareCrop: "",
        },
        teacher: {
          firstName: "",
          lastName: "",
          profilePicture: {
            mediumSquareCrop: "",
            fullSize: "",
            thumbnail: "",
          },
        },
      },
      newPrice: null,
      retriedToLoad: false,
      showCodeInput: false,
      code: "",
      countries: countries,
      customer_phone_number: "",
      customer_address: "",
      customer_city: "",
      customer_country: "",
      customer_state: "",
      customer_zip_code: "",
      isCreditCardPayment: false,
    };
  },
  methods: {
    async loadCourse() {
      await this.$http
        .get(
          `courses/${this.$route.params.idCourse}/details/?currency=${this.selectedCurrency}`
        )
        .then((res) => {
          if (res.data.newPrice > 0) {
            this.newPrice = res.data.newPrice;
            this.code = res.data.code;
            this.isCouponValide = true;
          }
          this.course = res.data;
        });
    },
    numberFormat(n) {
      return numeral(n).format("0,0");
    },
    async getPaymentLinkRequest() {
      this.loading = true;
      let url = "/payment/process-checkout/";
      if (this.newPrice !== null) url += "?code=" + this.code;
      await this.$http
        .post(url, {
          currency: this.selectedCurrency,
          course: this.course.id,
          customer_phone_number: this.isCreditCardPayment
            ? this.customer_phone_number
            : "",
          customer_address: this.isCreditCardPayment
            ? this.customer_address
            : "",
          customer_city: this.isCreditCardPayment ? this.customer_city : "",
          customer_country: this.isCreditCardPayment
            ? this.customer_country
            : "",
          customer_state: this.isCreditCardPayment ? this.customer_state : "",
          customer_zip_code: this.isCreditCardPayment
            ? this.customer_zip_code
            : "",
        })
        .then((res) => {
          this.loading = false;
          this.$toast.success(
            "Vous serez rediriger sous peu vers le serveur de paiement."
          );
          location.assign(res.data.paymentUrl);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error(
            "Oups !<br/> Erreur interne chez notre partenaire financier.<br/>SVP contactez-nous sur WhatsApp/Telegram/Email pour assistance rapide. Merci",
            {
              position: "top",
              duration: 7000,
              maxToasts: 2
            }
          );
        });
    },
    async checkCode() {
      this.isPromoCodeChecking = true;

      await this.$http
        .post("/promocode/apply-discount", {
          code: this.code,
          course: this.course.id,
          currency: this.course.currency,
        })
        .then((response) => {
          this.isPromoCodeChecking = false;
          this.newPrice = response.data.newPrice;
          this.course.currency = response.data.currency;
          this.isCouponValide = true;
        })
        .catch(() => {
          this.isPromoCodeChecking = false;
          this.$toast.error("Coupon Invalide");
        });
    },
    onPaymentMethodChange() {
      this.isCreditCardPayment = !this.isCreditCardPayment;
    },
  },
  watch: {
    selectedCurrency() {
      this.loadCourse();
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadCourse();
    });
  },

  computed: {
    getPhoneClass() {
      if (this.customer_phone_number.length == 0) return "";
      if (this.customer_phone_number.length < 7) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getAdressClass() {
      if (this.customer_address.length == 0) return "";
      if (this.customer_address.length < 4) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getCityClass() {
      if (this.customer_city.length == 0) return "";
      if (this.customer_city.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getStateClass() {
      if (this.customer_state.length == 0) return "";
      if (this.customer_state.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    getZipClass() {
      if (this.customer_zip_code.length == 0) return "";
      if (this.customer_zip_code.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },

    isSendable() {
      return (
        this.isCreditCardPayment == true &&
        (this.customer_phone_number == "" ||
          this.customer_address == "" ||
          this.customer_city == "" ||
          this.customer_country == "" ||
          this.customer_state == "" ||
          this.customer_zip_code == "")
      );
    },
  },
};
</script>
<style scoped>
.b-0 {
  position: absolute;
  bottom: 0;
}
.code-input {
  width: 100px;
  border-radius: 4px;
}
.text-through {
  text-decoration: line-through;
}

@media only screen and (max-width: 750px) {
  .footer {
    margin-top: auto;
    top: 5vh !important;
  }
}
</style>
